import React from 'react'

// Ours
import SEO from '../components/SEO'
import BlockWrapper from '../components/MainBlocks/BlockWrapper'
import { H1, H2, H3, H4, HPage } from '../components/Headings'
import { Wordmark } from '../components/Logo'
import Address from '../components/Address'
import Button from '../components/Button'
import CallToAction from '../components/CallToAction'
import Testimonial from '../components/Testimonial'

const Demo = props => {
  return (
    <div className="mb2">
      <span className="px1 line-height-1 h6 black">
        {props.children.type.displayName} {props.label}
      </span>
      <div
        className={`p2 md-p3 rounded ${
          props.dark ? 'bg-black border-black' : 'bg-white'
        }`}>
        {props.children}
      </div>
    </div>
  )
}

const Styleguide = props => {
  let copy =
    'Wayfinding and environmental design that moves people, projects and ideas forward.'

  return (
    <React.Fragment>
      <SEO title="Styleguide">
        <meta name="robots" content="noindex" />
      </SEO>
      <BlockWrapper bg="silver" maxWidth={5}>
        <Demo label="with maxWidth">
          <Wordmark style={{ maxWidth: '200px' }} />
        </Demo>
        <Demo label="in white with maxWidth" dark>
          <Wordmark color="white" style={{ maxWidth: '200px' }} />
        </Demo>
        <Demo label="in black with smaller maxWidth">
          <Wordmark color="black" style={{ maxWidth: '100px' }} />
        </Demo>
        <Demo label="">
          <Wordmark color="magenta" style={{ maxWidth: '100px' }} />
        </Demo>
        <Demo label="H1 component">
          <H1>{copy}</H1>
        </Demo>
        <Demo label="H2 component">
          <H2>{copy}</H2>
        </Demo>
        <Demo label="H3 component">
          <H3>{copy}</H3>
        </Demo>
        <Demo label="H4 component">
          <H4>{copy}</H4>
        </Demo>
        <Demo label="H4 + paragraph">
          <div>
            <H4>{copy}</H4>
            <p className="mt0">
              Placemaking Our goal is to attain a practical understanding of the
              necessary requirements for wayfinding.
            </p>
          </div>
        </Demo>
        <Demo label="paragraph with hyphens">
          <p className="hyphenate">
            Our knowledge-based design process and versatile product solutions
            provide effecient and effective wayfinding in complex healthcare
            environments. We ensure that your system will improve the patient
            experience and positively project your brand identity within the
            built environment, and that all graphics, colours and tactile
            treatments meet informationaland accessibility requirements. A
            well-planned, well-designed wayfinding solution helps minimize
            confusion and patient stress and creates a comfortable, intuitive
            means of navigating unfamiliar environments.
          </p>
        </Demo>
        <Demo label="HPage component">
          <HPage>{copy}</HPage>
        </Demo>
        <Demo label="Address component">
          <Address
            unit="202"
            streetAddress="1005 Broad Street"
            city="Victoria"
            province="BC"
            postalCode="V8W 2A1"
          />
        </Demo>
        <Demo label="Button component">
          <Button href="https://example.com">Download</Button>
        </Demo>
        <Demo label="Call to Action component">
          <CallToAction />
        </Demo>
        <Demo label="Testimonial">
          <Testimonial
            quote={`“${copy}”`}
            author="Author"
            organization="Organization"
          />
        </Demo>
        <Demo label="Testimonial">
          <Testimonial quote={`“${copy}”`} author="Author only" />
        </Demo>
        <Demo label="Testimonial">
          <Testimonial quote={`“${copy}”`} organization="Organization only" />
        </Demo>
      </BlockWrapper>
    </React.Fragment>
  )
}

export default Styleguide
